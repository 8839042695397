import React, { Component } from "react";
import { connect } from "react-redux";
import "./experience.scss";
import util from "../../_helpers/util";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { confirmAlert } from "react-confirm-alert";
const step = 4;
const page = "experiences";

class Experience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experiences: 1,
      references: 1,
      form: {
        nvam_experience: "no",
        no_past_experience: ['yes'],
        no_past_reference: ['yes'],
        step: step,
        reference1: "",
        reference2: "",
        reference3: "",
        seperation_reason: "",
        whichCompany:"",
        last_employer1: "",
        reference1Position: "",
        reference1StartDate: "",
        reference1EndDate: "",
        reference1Address: "",
        reference1City: "",
        reference1State: "",
        reference1Supervisor: "",
        reference1AltContact: "",
        reference1Telephone: "",
        reference1Fax: "",

        last_employer2: "",
        reference2Position: "",
        reference2StartDate: "",
        reference2EndDate: "",
        reference2Address: "",
        reference2City: "",
        reference2State: "",
        reference2Supervisor: "",
        reference2AltContact: "",
        reference2Telephone: "",
        reference2Fax: "",

        last_employer3: "",
        reference3Position: "",
        reference3StartDate: "",
        reference3EndDate: "",
        reference3Address: "",
        reference3City: "",
        reference3State: "",
        reference3Supervisor: "",
        reference3AltContact: "",
        reference3Telephone: "",
        reference3Fax: "",

        reference1name: "",
        reference1Relationship: "",
        reference1Phone: "",
        reference1Known: "",

        reference2name: "",
        reference2Relationship: "",
        reference2Phone: "",
        reference2Known: "",

        reference3name: "",
        reference3Relationship: "",
        reference3Phone: "",
        reference3Known: "",

        personalreference1: "",
        personalreference2: "",
        personalreference3: "",

        applyingCompany:"2",
      }
    };
    this.validateOptionalFields = this.validateOptionalFields.bind(this)
  }

  showref() {
    if (this.state.references < 3)
      this.setState({
        references: ++this.state.references
      });
  }

  blur(event) {
    util.blur(event);
  }

  setValue(event) {
    util.setValue.call(this, event);
    if (util.isblurred(event.target)) {
      var valid = util.validate(event.target);
    }
  }
  handleDateChange(name, date) {
    util.setValue.call(this, null, {
      value: date,
      name: name
    });
  }


  validateOptionalFields() {
    const {
      last_employer2,
      reference2Position,
      reference2StartDate,
      reference2EndDate,
      reference2Address,
      reference2City,
      reference2State,
      reference2Supervisor,
      reference2AltContact,
      reference2Telephone,
      reference2Fax,
      reference2Responsibilities,
      reference2ResonForLeaving,
      last_employer3,
      reference3Position,
      reference3StartDate,
      reference3EndDate,
      reference3Address,
      reference3City,
      reference3State,
      reference3Supervisor,
      reference3AltContact,
      reference3Telephone,
      reference3Fax,
      reference3Responsibilities,
      reference3ResonForLeaving,
      reference2name,
      reference2Relationship,
      reference2Phone,
      reference2Known,
      reference3name,
      reference3Relationship,
      reference3Phone,
      reference3Known,
    } = this.state.form;

    let errorMessage = "";

    if (
      (last_employer2 &&
        reference2Position &&
        reference2StartDate &&
        reference2EndDate &&
        reference2Address &&
        reference2City &&
        reference2State &&
        reference2Supervisor &&
        reference2AltContact &&
        reference2Telephone &&
        reference2Fax &&
        reference2Responsibilities &&
        reference2ResonForLeaving) ||
      (!last_employer2 &&
        !reference2Position &&
        !reference2StartDate &&
        !reference2EndDate &&
        !reference2Address &&
        !reference2City &&
        !reference2State &&
        !reference2Supervisor &&
        !reference2AltContact &&
        !reference2Telephone &&
        !reference2Fax &&
        !reference2Responsibilities &&
        !reference2ResonForLeaving)
    ) {
      //if all fields are present, then no error
      //if some fields are not present, then error
    } else {
      errorMessage =
        "If Past Experiences / Employment Record / References - #2 is applicable, all corresponding fields must not be empty.";
    }

    if (
      (last_employer3 &&
        reference3Position &&
        reference3StartDate &&
        reference3EndDate &&
        reference3Address &&
        reference3City &&
        reference3State &&
        reference3Supervisor &&
        reference3AltContact &&
        reference3Telephone &&
        reference3Fax &&
        reference3Responsibilities &&
        reference3ResonForLeaving) ||
      (!last_employer3 &&
        !reference3Position &&
        !reference3StartDate &&
        !reference3EndDate &&
        !reference3Address &&
        !reference3City &&
        !reference3State &&
        !reference3Supervisor &&
        !reference3AltContact &&
        !reference3Telephone &&
        !reference3Fax &&
        !reference3Responsibilities &&
        !reference3ResonForLeaving)
    ) {
    } else {
      errorMessage =
        "If Past Experiences / Employment Record / References - #3 is applicable, all corresponding input fields must not be empty.";
    }

    if (
      (reference2name &&
        reference2Relationship &&
        reference2Phone &&
        reference2Known) ||
      (!reference2name &&
        !reference2Relationship &&
        !reference2Phone &&
        !reference2Known)
    ) {
    } else {
      errorMessage =
        "If Professional Reference #2 is applicable, all corresponding input fields must not be empty.";
    }

    if (
      (reference3name &&
        reference3Relationship &&
        reference3Phone &&
        reference3Known) ||
      (!reference3name &&
        !reference3Relationship &&
        !reference3Phone &&
        !reference3Known)
    ) {
    } else {
      errorMessage =
        "If Professional Reference #3 is applicable, all corresponding input fields must not be empty.";
    }

    if (errorMessage) {
      confirmAlert({
        title: "Error saving data!",
        message: errorMessage,
        buttons: [
          {
            label: "Close",
            onClick: () => {},
          },
        ],
      });
    }

    return errorMessage ? true : false;
  }
  
  
  submitForm(event) {
    event.preventDefault();

    const isCityEmptyRegEx = /^\s*$/;
    const form = this.state.form;
    let errorMessage = "";

    if(form.no_past_experience.length === 0){
      if (isCityEmptyRegEx.test(form.reference1City)) {
        errorMessage =
          "The field City for the Past Experiences / Employment Record / References - #1 is required!";
      } else if (form.reference1City.length < 2) {
        errorMessage =
          "The minimum required character length for the field Past Experiences / Employment Record / References city - #1 is 2!";
      } else if (form.reference1City.length > 28) {
        errorMessage =
          "The maximum allowed character length for the field Past Experiences / Employment Record / References city - #1 is 28!";
      } 
    }

    if (errorMessage !== "" && form.no_past_experience.length === 0) {
      return confirmAlert({
        title: "Error saving data!",
        message: errorMessage,
        buttons: [
          {
            label: "Close",
            onClick: () => {},
          },
        ],
      });
    } 

    if (
      isCityEmptyRegEx.test(form.reference1name) ||
      isCityEmptyRegEx.test(form.reference1Relationship) ||
      isCityEmptyRegEx.test(form.reference1Phone) ||
      isCityEmptyRegEx.test(form.reference1Known)
    ) {
      errorMessage = "The past reference 1 fields can't be empty!";
    }

    if (errorMessage !== "" && form.no_past_reference.length === 0) {
      return confirmAlert({
        title: "Error saving data!",
        message: errorMessage,
        buttons: [
          {
            label: "Close",
            onClick: () => {},
          },
        ],
      });
    } 

    if(this.validateOptionalFields()) return;
    
    "function" === typeof this.props.action &&
      this.props.action({ ...this.state.form, step }, step);
  }
  componentDidMount() {
    if (this.props.api && this.props.api.aplicationDetail) {
      var data = this.props.api.aplicationDetail,
        obj = Object.assign({}, this.state.form);
      this.setState(
        {
          form: util._merge(
            obj,
            data,
            {
              worked_for_nvam: "nvam_experience",
              seperation_reason: "reason_leave_nvam",
              whichCompany:"which_company",
              reference1: "reference1",
              reference2: "reference2",
              reference3: "reference3",
              personalreference1: "personalreference1",
              personalreference2: "personalreference2",
              personalreference3: "personalreference3"
            },
            res => {
              return this.setReference(res);
            }
          )
        }
      );
    }
  }
  setReference(res) {
    var res = util.extractObjectAndMerge(
      res,
      {
        start_date: ["reference", "StartDate"],
        end_date: ["reference", "EndDate"],
        last_employee: "last_employer",
        city_id: ["reference", "City"],
        address: ["reference", "Address"],
        supervisor_name: ["reference", "Supervisor"],
        telephone: ["npm run startreference", "Telephone"],
        position: ["reference", "Position"],
        state_id: ["reference", "State"],
        fax: ["reference", "Fax"],
        alt_contact_name: ["reference", "AltContact"],
        responsibilities: ["reference", "Responsibilities"],
        reason_for_leaving: ["reference", "ResonForLeaving"],
        telephone: ["reference", "Telephone"]
      },
      ["reference1", "reference2", "reference3"]
    );
    res = util.extractObjectAndMerge(
      res,
      {
        name: ["reference", "name"],
        relationship: ["reference", "Relationship"],
        phone: ["reference", "Phone"],
        known: ["reference", "Known"]
      },
      ["personalreference1", "personalreference2", "personalreference3"]
    );
    return res;
  }
  bindShowExpSections() {
    if (this.state.experiences < 3)
      this.setState({
        experiences: ++this.state.experiences
      });
  }
  render() {
    return (
      <div className="aplcn-form aplcn-form-one">
        <form method="post" onSubmit={this.submitForm.bind(this)} id={page}>
          <div className="aplcn-form-section-head">
            <h4>Professional References</h4>
          </div>

          <div className="aplcn-form-section-head">
            <h5 className="aplcn-is-graduate">
              Have you worked for NVAM or Firestorm in the past?*
            </h5>
          </div>
          <div className="aplcn-form-row-main">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="radio"
                  id="nvam_experience1"
                  onChange={this.setValue.bind(this)}
                  name="form.nvam_experience"
                  defaultChecked={
                    this.state.form.nvam_experience === "yes"
                  }
                />
                <label htmlFor="nvam_experience1">
                  <span className="aplcn-check-label">Yes</span>
                </label>
              </li>
              <li>
                <input
                  value="no"
                  type="radio"
                  id="nvam_experience3"
                  onChange={this.setValue.bind(this)}
                  name="form.nvam_experience"
                  defaultChecked={
                    this.state.form.nvam_experience !== "yes"
                  }
                />
                <label htmlFor="nvam_experience3">
                  <span className="aplcn-check-label">No</span>
                </label>
              </li>
            </ul>
          </div>
          {"yes" === this.state.form.nvam_experience && (
            <div>
              <div className="aplcn-form-section-head">
                <h4>
                  If Yes, 
                </h4>
                <h5 className="aplcn-is-graduate">Which Company?</h5>
              </div>
              <div className="aplcn-form-row-main">
              <select
                  required
                  onBlur={this.blur.bind(this)}
                  onChange={this.setValue.bind(this)}
                  className=""
                  value={this.state.form.whichCompany}
                  name="form.whichCompany"
                >
                  <option value="1">--- Select Company ---</option>
                  <option value="NVAM">NVAM</option>
                  <option value="Firestorm">Firestorm</option>
                </select>
              </div>
              <div className="aplcn-form-section-head">
                <h5 className="aplcn-is-graduate">
                  What was the reason for separation? Who was your
                  supervisor?
                </h5>
              </div>
              <div className="aplcn-form-row-main">
                <input
                  type="text"
                  placeholder="Please explain…"
                  name="form.seperation_reason"
                  required
                  onChange={this.setValue.bind(this)}
                  onBlur={this.blur.bind(this)}
                  value={this.state.form.seperation_reason}
                />
              </div>
            </div>
          )}

          {/* No Past Experiences */}
          <div className="aplcn-form-row-main pad-v-2">
            <ul className="aplcn-checklist inline-radios">
              <li>
                <input
                  value="yes"
                  type="checkbox"
                  id="no_past_experience"
                  onChange={this.setValue.bind(this)}
                  name="form.no_past_experience"
                  defaultChecked={this.state.form.no_past_experience.length > 0}
                />
                <label htmlFor="no_past_experience">
                  <span className="aplcn-check-label">No Past Experience</span>
                </label>
              </li>
            </ul>
          </div>

          {/* Section 1 */}
          <div className="past-employer-section">
            <div className="aplcn-form-section-head">
              <h4>Past Experiences / Employment Record / References - #1</h4>
            </div>
            <div className="aplcn-form-row-main aplcn-has-inputs">
              <div className="row aplcn-row">
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <input
                    type="text"
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    placeholder="Last Employer"
                    name="form.last_employer1"
                    // required
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.last_employer1}
                  />
                </div>
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <input
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    type="text"
                    placeholder="Position Held"
                    name="form.reference1Position"
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1Position}
                  />
                </div>
              </div>
            </div>

            <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
              <div className="row aplcn-row">
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <DatePicker
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    selected={this.state.form.reference1StartDate}
                    showYearDropdown
                    onChange={this.handleDateChange.bind(
                      this,
                      "form.reference1StartDate"
                    )}
                    placeholderText="Start Date MM/DD/YY"
                    dateFormat={util._datePickerFormat()}
                  />
                </div>
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <DatePicker
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    selected={this.state.form.reference1EndDate}
                    showYearDropdown
                    onChange={this.handleDateChange.bind(
                      this,
                      "form.reference1EndDate"
                    )}
                    placeholderText="End Date MM/DD/YY"
                    dateFormat={util._datePickerFormat()}
                  />
                </div>
              </div>
            </div>

            <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
              <div className="row aplcn-row">
                <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                  <input
                    type="text"
                    placeholder="Address"
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1Address}
                    name="form.reference1Address"
                  />
                </div>
                <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                  <input
                    type="text"
                    placeholder="City"
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1City}
                    name="form.reference1City"
                  />
                </div>
                <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                  <input
                    type="text"
                    placeholder="State"
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1State}
                    name="form.reference1State"
                  />
                </div>
              </div>
            </div>

            <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
              <div className="row aplcn-row">
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <input
                    type="text"
                    placeholder="Supervisors name"
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1Supervisor}
                    name="form.reference1Supervisor"
                  />
                </div>
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <input
                    type="text"
                    placeholder="Alternate contact name"
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1AltContact}
                    name="form.reference1AltContact"
                  />
                </div>
              </div>
            </div>

            <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
              <div className="row aplcn-row">
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <NumberFormat
                    placeholder="Telephone number"
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    data-validation="phonenumber"
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    name="form.reference1Telephone"
                    value={this.state.form.reference1Telephone}
                    format="(###) ###-####"
                    mask=""
                    title="Telephone number"
                  />
                </div>
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <input
                    type="text"
                    placeholder="Fax"
                    disabled={this.state.form.no_past_experience.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1Fax}
                    name="form.reference1Fax"
                    maxlength="20"
                  />
                </div>
              </div>
            </div>

            <div className="aplcn-form-section-head">
              <h4>Responsibilities</h4>
            </div>

            <div className="aplcn-form-row-main aplcn-has-inputs">
              <div className="row aplcn-row">
                <div className="col-sm-12 col-md-12 aplcn-inp-wrap aplcn-note-text-wrapper">
                  <textarea
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1Responsibilities}
                    name="form.reference1Responsibilities"
                    placeholder="Please fill out your responsibilities for this position…"
                  />
                </div>
              </div>
            </div>

            <div className="aplcn-form-section-head">
              <h4>Reason For Leaving</h4>
            </div>

            <div className="aplcn-form-row-main aplcn-has-inputs">
              <div className="row aplcn-row">
                <div className="col-sm-12 col-md-12 aplcn-inp-wrap aplcn-note-text-wrapper">
                  <textarea
                    required={this.state.form.no_past_experience.length === 0}
                    disabled={this.state.form.no_past_experience.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1ResonForLeaving}
                    name="form.reference1ResonForLeaving"
                    placeholder="Please describe your reason for leaving…"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <!--section -2 --> */}

          {(
            <div className="past-employer-section">
              <div className="aplcn-form-section-head">
                <h4>Past Experiences / Employment Record / References - #2 (if applicable)</h4>
              </div>
              <div className="aplcn-form-row-main aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Last Employer"
                      name="form.last_employer2"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.last_employer2}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Position Held"
                      name="form.reference2Position"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2Position}
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <DatePicker
                      selected={this.state.form.reference2StartDate}
                      showYearDropdown
                      onChange={this.handleDateChange.bind(
                        this,
                        "form.reference2StartDate"
                      )}
                      placeholderText="Start Date MM/DD/YY"
                      dateFormat={util._datePickerFormat()}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <DatePicker
                      selected={this.state.form.reference2EndDate}
                      showYearDropdown
                      onChange={this.handleDateChange.bind(
                        this,
                        "form.reference2EndDate"
                      )}
                      placeholderText="End Date MM/DD/YY"
                      dateFormat={util._datePickerFormat()}
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Address"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2Address}
                      name="form.reference2Address"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="City"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2City}
                      name="form.reference2City"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="State"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2State}
                      name="form.reference2State"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Supervisors name"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2Supervisor}
                      name="form.reference2Supervisor"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input

                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Alternate contact name"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2AltContact}
                      name="form.reference2AltContact"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    {/* <input
                      type="text"
                      placeholder="Telephone number"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2Telephone}
                      name="form.reference2Telephone"
                    /> */}

                    <NumberFormat
                      placeholder="Telephone number"
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      data-validation="phonenumber"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      name="form.reference2Telephone"
                      value={this.state.form.reference2Telephone}
                      format="(###) ###-####"
                      mask=""
                      title="Telephone number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      type="text"
                      placeholder="Fax"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2Fax}
                      name="form.reference2Fax"
                      maxlength="20"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-section-head">
                <h4>Responsibilities</h4>
              </div>

              <div className="aplcn-form-row-main aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-12 aplcn-inp-wrap aplcn-note-text-wrapper">
                    <textarea
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2Responsibilities}
                      name="form.reference2Responsibilities"
                      placeholder="Please fill out your responsibilities for this position…"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-section-head">
                <h4>Reason For Leaving</h4>
              </div>

              <div className="aplcn-form-row-main aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-12 aplcn-inp-wrap aplcn-note-text-wrapper">
                    <textarea
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2ResonForLeaving}
                      name="form.reference2ResonForLeaving"
                      placeholder="Please describe your reason for leaving…"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <!-- section three --> */}

          {(
            <div className="past-employer-section">
              <div className="aplcn-form-section-head">
                <h4>Past Experiences / Employment Record / References - #3 (if applicable)</h4>
              </div>
              <div className="aplcn-form-row-main aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Last Employer"
                      name="form.last_employer3"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.last_employer3}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Position Held"
                      name="form.reference3Position"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3Position}
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <DatePicker
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      selected={this.state.form.reference3StartDate}
                      showYearDropdown
                      onChange={this.handleDateChange.bind(
                        this,
                        "form.reference3StartDate"
                      )}
                      placeholderText="Start Date MM/DD/YY"
                      dateFormat={util._datePickerFormat()}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">

                    <DatePicker
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      selected={this.state.form.reference3EndDate}
                      showYearDropdown
                      onChange={this.handleDateChange.bind(
                        this,
                        "form.reference3EndDate"
                      )}
                      placeholderText="End Date MM/DD/YY"
                      dateFormat={util._datePickerFormat()}
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Address"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3Address}
                      name="form.reference3Address"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="City"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3City}
                      name="form.reference3City"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="State"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3State}
                      name="form.reference3State"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Supervisors name"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3Supervisor}
                      name="form.reference3Supervisor"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input

                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Alternate contact name"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3AltContact}
                      name="form.reference3AltContact"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    {/* <input
                      type="text"
                      placeholder="Telephone number"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3Telephone}
                      name="form.reference3Telephone"
                    /> */}

                    <NumberFormat
                      placeholder="Telephone number"
                      // required={this.state.form.no_past_experience.length === 0}
                      disabled={this.state.form.no_past_experience.length !== 0}
                      data-validation="phonenumber"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      name="form.reference3Telephone"
                      value={this.state.form.reference3Telephone}
                      format="(###) ###-####"
                      mask=""
                      title="Telephone number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      disabled={this.state.form.no_past_experience.length !== 0}
                      type="text"
                      placeholder="Fax"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3Fax}
                      name="form.reference3Fax"
                      maxlength="20"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-section-head">
                <h4>Responsibilities</h4>
              </div>

              <div className="aplcn-form-row-main aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-12 aplcn-inp-wrap aplcn-note-text-wrapper">
                    <textarea
                      disabled={this.state.form.no_past_experience.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3Responsibilities}
                      name="form.reference3Responsibilities"
                      placeholder="Please fill out your responsibilities for this position…"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-section-head">
                <h4>Reason For Leaving</h4>
              </div>

              <div className="aplcn-form-row-main aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-12 aplcn-inp-wrap aplcn-note-text-wrapper">
                    <textarea
                      disabled={this.state.form.no_past_experience.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3ResonForLeaving}
                      name="form.reference3ResonForLeaving"
                      placeholder="Please describe your reason for leaving…"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

         





          {/* REFERENCES */}
          <div className="personal-reference-one">
            <div className="aplcn-form-section-head">
              <h4>Professional Reference #1</h4>
            </div>
            {/* No Past Reference */}
            <div className="aplcn-form-row-main pad-v-2">
              <ul className="aplcn-checklist inline-radios">
                <li>
                  <input
                    value="yes"
                    type="checkbox"
                    id="no_past_reference"
                    onChange={this.setValue.bind(this)}
                    name="form.no_past_reference"
                    defaultChecked={this.state.form.no_past_reference.length > 0}
                  />
                  <label htmlFor="no_past_reference">
                    <span className="aplcn-check-label">No Past Reference</span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="aplcn-form-row-main aplcn-has-inputs">
              <div className="row aplcn-row">
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <input
                    type="text"
                    placeholder="Name"
                    required={this.state.form.no_past_reference.length === 0}
                    disabled={this.state.form.no_past_reference.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1name}
                    name="form.reference1name"
                  />
                </div>
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <input
                    type="text"
                    placeholder="Relationship"
                    required={this.state.form.no_past_reference.length === 0}
                    disabled={this.state.form.no_past_reference.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1Relationship}
                    name="form.reference1Relationship"
                  />
                </div>
              </div>
            </div>

            <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
              <div className="row aplcn-row">
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  {/* <input
                    type="text"
                    placeholder="Phone number"
                    required
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1Phone}
                    name="form.reference1Phone"
                  /> */}

                  <NumberFormat
                    placeholder="Phone number"
                    required={this.state.form.no_past_reference.length === 0}
                    disabled={this.state.form.no_past_reference.length !== 0}
                    // required
                    data-validation="phonenumber"
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    name="form.reference1Phone"
                    value={this.state.form.reference1Phone}
                    format="(###) ###-####"
                    mask=""
                    title="Phone number"
                  />
                </div>
                <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                  <input
                    type="text"
                    placeholder="How long have you know them?"
                    required={this.state.form.no_past_reference.length === 0}
                    disabled={this.state.form.no_past_reference.length !== 0}
                    onChange={this.setValue.bind(this)}
                    onBlur={this.blur.bind(this)}
                    value={this.state.form.reference1Known}
                    name="form.reference1Known"
                  />
                </div>
              </div>
            </div>
          </div>

          {(
            <div className="personal-reference-one">
              <div className="aplcn-form-section-head">
                <h4>Professional Reference #2</h4>
              </div>
              <div className="aplcn-form-row-main aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      type="text"
                      placeholder="Name"
                      // required={this.state.form.no_past_reference.length === 0}
                      disabled={this.state.form.no_past_reference.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2name}
                      name="form.reference2name"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      type="text"
                      placeholder="Relationship"
                      // required={this.state.form.no_past_reference.length === 0}
                      disabled={this.state.form.no_past_reference.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2Relationship}
                      name="form.reference2Relationship"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    {/* <input
                     type="text"
                     placeholder="Phone number"
                     required
                     onChange={this.setValue.bind(this)}
                     onBlur={this.blur.bind(this)}
                     value={this.state.form.reference2Phone}
                     name="form.reference2Phone"
                   /> */}

                    <NumberFormat
                      placeholder="Phone number"
                      // required={this.state.form.no_past_reference.length === 0}
                      disabled={this.state.form.no_past_reference.length !== 0}
                      data-validation="phonenumber"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      name="form.reference2Phone"
                      value={this.state.form.reference2Phone}
                      format="(###) ###-####"
                      mask=""
                      title="Phone number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      type="text"
                      placeholder="How long have you know them?"
                      // required={this.state.form.no_past_reference.length === 0}
                      disabled={this.state.form.no_past_reference.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference2Known}
                      name="form.reference2Known"
                    />
                  </div>
                </div>
              </div>

            </div>
          )}

          {(
            <div className="personal-reference-one">
              <div className="aplcn-form-section-head">
                <h4>Professional Reference #3</h4>
              </div>
              <div className="aplcn-form-row-main aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      type="text"
                      placeholder="Name"
                      // required={this.state.form.no_past_reference.length === 0}
                      disabled={this.state.form.no_past_reference.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3name}
                      name="form.reference3name"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      type="text"
                      placeholder="Relationship"
                      // required={this.state.form.no_past_reference.length === 0}
                      disabled={this.state.form.no_past_reference.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3Relationship}
                      name="form.reference3Relationship"
                    />
                  </div>
                </div>
              </div>

              <div className="aplcn-form-row-main aplcn-has-top-space aplcn-has-inputs">
                <div className="row aplcn-row">
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    {/* <input
                     type="text"
                     placeholder="Phone number"
                     required
                     onChange={this.setValue.bind(this)}
                     onBlur={this.blur.bind(this)}
                     value={this.state.form.reference3Phone}
                     name="form.reference3Phone"
                   /> */}

                    <NumberFormat
                      placeholder="Phone number"
                      // required={this.state.form.no_past_reference.length === 0}
                      disabled={this.state.form.no_past_reference.length !== 0}
                      data-validation="phonenumber"
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      name="form.reference3Phone"
                      value={this.state.form.reference3Phone}
                      format="(###) ###-####"
                      mask=""
                      title="Phone number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 aplcn-inp-wrap">
                    <input
                      type="text"
                      placeholder="How long have you know them?"
                      // required={this.state.form.no_past_reference.length === 0}
                      disabled={this.state.form.no_past_reference.length !== 0}
                      onChange={this.setValue.bind(this)}
                      onBlur={this.blur.bind(this)}
                      value={this.state.form.reference3Known}
                      name="form.reference3Known"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

         

          <div className="row">
            <div className="col-sm-12 aplcn-form-main-actionbar">
              <div className="aplcn-form-actionbar">
                <button onClick={this.back.bind(this)} type="button" disabled="" className="aplcn-prev-button">
                  Previous
                </button>
                <button type="submit" className="aplcn-next-btn">
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
  back(event) {
    event.preventDefault();
    this.props.back(step);
  }
}
function mapStateToProps(state) {
  return state;
}
const connectedApp = connect(mapStateToProps)(Experience);
export { connectedApp as Experience };
